<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-body">
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="row">
              <div class="col-md-6 col-6">
                <img
                  src="/app-assets/images/software-development.gif"
                  style="
                    max-width: 100%;
                    font-weight: 600;
                    font-family: 'Poppins,poppins';
                    font-size: 41px;
                    margin: 0;
                  "
                  alt="thank-you"
                />
              </div>
              <div class="col-md-6 col-6">
                <br />
                <br />
                <br />
                <br />
                <h2 class="heading-red">Thank You<br />for Getting in Touch !</h2>
                <p class="p1">
                  We are processing your Request. Our Experts will reach you out soon.
                </p>
                <h2 class="heading-red-title">
                  We're excited to kickstart your company registration
                </h2>
                <p class="p2">
                  100% Paperless. Expert Assistance. 7 Days Registration Guarantee.
                </p>
                <center>
                  <router-link to="/retailer/home" class="btn btn-danger btn-sm">
                    Go to Home Page
                  </router-link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
export default {
  name: "ThankYou",
  data() {
    return {
      name: "Thank",
    };
  },
  methods: {
    loadData() {
      console.log("Thank-You");
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadData();
      if (localStorage.getItem("service") != null) {
        localStorage.removeItem("service");
      }
    }
  },
};
</script>

<style scoped>
.heading-red {
  color: #f21300;
  font-weight: 600;
  font-family: "Poppins", poppins;
  font-size: 41px;
  margin: 0;
  text-align: center;
}
.heading-red-title {
  color: #f21300;
  margin: 0;
  font-family: "Noto Serif", poppins;
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
}
.p1 {
  margin-bottom: 1.6em;
  box-sizing: border-box;
  overflow-wrap: break-word;

  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;

  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  text-align: center;
  font-family: "Quando", poppins;

  color: #000;
}
.p2 {
  margin-bottom: 1.6em;
  box-sizing: border-box;
  overflow-wrap: break-word;

  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;

  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  text-align: center;
  font-family: "Quando", poppins;

  color: #000;
}
</style>
